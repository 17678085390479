import React from "react";
import { useAuth } from '../components/authprovider.component';
/** BOOTSTRAP */
import { Nav, Tab } from 'react-bootstrap';

/** COMPONENTES */
import NavbarMenu from '../components/navbar.component';

const BoardRRHH = () => {
    const auth = useAuth();

    return (
        <div>
            <NavbarMenu auth={auth} />
        <div className="container mt-3 fondo_general">
            <header className="jumbotron bg-light p-4">
                <h3>Sección de Reportes RRHH</h3>
            </header>
            <br />
            <Tab.Container>
                <table className="table">
                    <tr>
                        <td><Nav.Link className="text-center" href="/reporterrhh/1/ReportSection52db391b319f618b7a7c" eventKey="#link3" aria-describedby="reporte_tarea"><img src="/img/iconos/detalle.png" alt="Reporte de Tareas" width="100" height="100" /></Nav.Link><div id="reporte_tarea" className="form-text text-center">Reporte General</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/10/ReportSection28ebe681c113a0688240" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Personal Activo</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/11/ReportSectiond9b25dfe2d88135063f0" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Licencia</div></td>
                        {/* <td><Nav.Link className="text-center" href="/reporterrhh/2/ReportSectionc6d2805c81af47372cbc" aria-describedby="reporte_rendimiento"><img src="/img/iconos/rendimiento.png" alt="Reporte de Rendimiento" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Pack Basico y Contratos</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/3/ReportSectionbf0f230bb9df3bc44e64" aria-describedby="reporte_rendimiento"><img src="/img/iconos/cuadrilla.png" alt="Reporte de Cuadrilla" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Barreras y Certificados</div></td> */}
                    </tr>
                    {/* <tr>
                        <td><Nav.Link className="text-center" href="/reporterrhh/4/ReportSectioncd814389c92622e90752" aria-describedby="reporte_rendimiento"><img src="/img/iconos/casino.png" alt="Reporte de Equipos Casino" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Operadores</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/5/ReportSectionded77d55afdff102c4bc" aria-describedby="reporte_rendimiento"><img src="/img/iconos/portones.png" alt="Reporte de Portones" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Documentacion Operadores</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/6/ReportSectionc90a4677e8c1aaf2164f" aria-describedby="reporte_rendimiento"><img src="/img/iconos/acumulado.png" alt="Reporte de Pendiente Acumulado" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Resumen Examenes Basicos</div></td>
                    </tr> */}
                    {/* <tr>
                        <td><Nav.Link className="text-center" href="/reporterrhh/7/ReportSection1c04ecd2c60d41434807" aria-describedby="reporte_hora"><img src="/img/iconos/hora.png" alt="Reporte de Promedio de Horas" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Resumen Contratos</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/8/ReportSection3b23e447b87191c5ea74" aria-describedby="reporte_hora"><img src="/img/iconos/estandarizacion.png" alt="Reporte de Estandarización de Tableros" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Conductores</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/9/ReportSectiondf63fd375e50da23c424" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Mantencion MEL" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Auditoria Deloitte</div></td>
                    </tr> */}
                    <tr>
                        <td><Nav.Link className="text-center" href="/reporterrhh/12/ReportSection540ccd372b22f19e9247" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Vacaciones</div></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/14/ReportSectiondff4173d2d5ebc7cd285" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Documentos Vencidos/Por Vencer</div></td>
                    </tr>
                    <tr>
                        {/* <td><Nav.Link className="text-center" href="/reporterrhh/13/ReportSection94858366603e4fe257e5" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Resoluciones</div></td> */}
                        <td><Nav.Link className="text-center" href="/reportereclu/14/ReportSection7fc3634554e7d92ad67a" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Estado DNC por Cargo</div></td>
                        <td><Nav.Link className="text-center" href="/reportereclu/14/ReportSection9e6205d7b629381d0d48" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Estado DNC por Unidad de Negocio</div></td>
                        <td><Nav.Link className="text-center" href="/reportereclu/14/e2565cfc1c499a893786" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Estado DNC por Unidad de Negocio Versión 2</div></td>
                    </tr>
                    <tr>
                        <td><Nav.Link className="text-center" href="/reportereclu/14/ReportSection8ea94dd45fd71d077e72" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Tiempos Por Estado</div></td>
                        <td><Nav.Link className="text-center" href="/reportereclu/14/8bd1063bfb30bfba78d0" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Tiempos Por Estado Versión 2</div></td>
                    </tr>
                </table>
            </Tab.Container>
        </div>
        </div>
    );
}

export default BoardRRHH;